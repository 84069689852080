import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import SeoLeftColumn from "../../components/SeoLeftColumn";
import SeoRightColumn from "../../components/SeoRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// 1. define Hreflang data, for href tags and language button
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/seo"
);


const Seo = ({ location }) => { // Receive location as a prop
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        {/* 2. define SEO meta data */}
        <SEO
          title="Tutorials om SEO & SEO-værktøjer"
          lang="da"
          description="Forbedr dine SEO-færdigheder med tutorials om SEO og DIY SEO-værktøjer. Med vores trin-for-trin vejledninger bliver du mester i søgeordsforskning, on-page optimering og mere."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
          canonical="/da/seo"
        />

        <MainFrontpage>
          <SeoLeftColumn />
          <SeoRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Seo);
